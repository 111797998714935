@font-face {
	font-family: 'Raleway';
	src: url('../Assets/Fonts/Raleway.ttf');
}

@font-face {
	font-family: 'Phudu';
	src: url('../Assets/Fonts/Phudu.ttf');
}

@font-face {
	font-family: 'Montserrat';
	src: url('../Assets/Fonts/Montserrat.ttf');
}

:root {
	--color1v1: #00ba96;
	--color1v2: #00836e;
	--color1v1Border: #00a485;
	--color1v2Border: #006758;
	--color1Text: white;

	--color2v1: #ae90bc;
	--color2v2: #a182b0;
	--color2v1Border: #a182b0;
	--color2v2Border: #816090;
	--color2Text: white;

	--inputColor: #cacaca;
	--inputText: black;

	--disabledButton: #8b8b8b;
	--disabledButtonBorder: #6d6d6d;
	--disabledButtonText: white;

	--borderRadius: 10px;
}

body {
	margin: 0;
	overflow-x: hidden;
	font-family: 'Raleway';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: inherit;
	text-decoration: inherit;
}

h1 {
	font-family: 'Phudu';
	margin: 0;
}

h4 {
	margin: 0;
}

.Phone {
	font-family: 'Montserrat';
}

.LoginPage {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100vh;
	gap: 2vh;
}

.MobilePage {
	height: 100vh;
	text-align: center;
	display: flex;
	align-items: center;
	font-size: 5em;
	font-weight: 500;
}

.LoginPage > img {
	margin: 15vh 0;
	height: 15vh;
}

.LoginPage > .inputField {
	min-width: 30%;
	font-size: 1.5em;
}

.inputField {
	box-sizing: border-box;
	border: none;
	background-color: var(--inputColor);
	color: var(--inputText);
	padding: 0.5em 1em;
	font-size: 1em;
	border-radius: var(--borderRadius);
}

.inputField:focus {
	outline: none;
}

.Main {
	display: grid;
	grid-template-columns: 6vw 1fr;
	height: 100%;
}

.App {
	width: 100%;
	min-height: 66vh;
	padding: 2vh 2vw;
}

.NavBar {
	width: 6vw;
	height: 100vh;
	position: sticky;
	top: 0;
	left: 0;
	background-color: #ede7ef;
	padding: 1vh 1vw;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	gap: 2vh;
	align-items: center;
}

.NavBarElement {
	background-color: #ede7ef;
	transition-duration: 150ms;
	padding: 1vw;
	border-radius: var(--borderRadius);
}

.NavBarElement:hover {
	background-color: #ded9e0;
}

.NavBarElement > img {
	width: 3.5vw;
}

.Footer {
	width: 80%;
	padding: 5vh 10%;
	min-height: 20vh;
	background-color: black;
	color: white;
	position: relative;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 3vh;
}

.FooterElement {
	font-size: 1em;
}

.E404 {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;
	height: 60vh;
}

.E404 > h1 {
	text-align: center;
	font-size: 3em;
}

.Button {
	width: fit-content;
}

.Button > button {
	cursor: pointer;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: var(--color1v1);
	color: var(--color1Text);
	transition-duration: 150ms;
	font-size: 1.2em;
	padding: 0.6em 1.2em;
	border: 2px solid var(--color1v1Border);
	border-radius: var(--borderRadius);
}

.Button > button:hover {
	background-color: var(--color1v2);
	border: 2px solid var(--color1v2Border);
}

.ButtonDisabled > button {
	background-color: var(--disabledButton);
	border: 2px solid var(--disabledButtonBorder);
	color: var(--disabledButtonText);
	cursor: not-allowed;
}

.ButtonDisabled > button:hover {
	background-color: var(--disabledButton);
	border: 2px solid var(--disabledButtonBorder);
}

.RedButton > button {
	background-color: var(--color2v2);
	border: 2px solid var(--color2v2Border);
	color: var(--color2Text);
}

.RedButton > button:hover {
	background-color: var(--color2v1);
	border: 2px solid var(--color2v1Border);
}

.GenericPage {
	display: flex;
	flex-direction: column;
	gap: 4vh;
}

.GenericPage > div {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto 0;
	gap: 2vh;
}

.ResponsesSettings {
	display: flex;
	flex-direction: column;
	gap: 2vh;
	& > div > input {
		cursor: grab;
	}
}

.Response {
	display: flex;
	gap: 1vw;
	& > img {
		cursor: pointer;
		width: 2vw;
	}
}

.GenericPage > div > input {
	font-size: 1.2em;
}

.GenericPage > div > h4 {
	font-size: 3vh;
}

.GenericPage > span > span {
	display: flex;
	justify-content: space-between;
	min-width: 15vw;
	max-width: max-content;
}

.RemovePage > div > span {
	font-size: 1.3em;
	font-weight: bold;
}

.CallerPage > span {
	display: flex;
	flex-direction: column;
	gap: 0.5vh;
}

.CallerPage > span > .Button {
	margin-top: 1.5vh;
}

.ExplorePage > div {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 2vh;
	margin: auto 0;
	align-items: center;
	justify-content: center;
}

.ExplorePage > div > div {
	display: flex;
	gap: 1vw;
}

.ExplorePage > div > div > input {
	font-size: 1.2em;
}

.ExploreList {
	display: flex;
	flex-direction: column;
	gap: 1vh;
}

.ExploreList > a {
	background-color: rgb(202, 202, 202);
	padding: 2vh 2vw;
	border-radius: var(--borderRadius);
}

.ClientsErrors {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-bottom: 5vh;
	gap: 1vh 1vw;
}

.ErrorsHeader {
	font-weight: bold;
}

.Settings {
	display: flex;
	flex-direction: column;
	gap: 5vh;
}

.Settings > div {
	display: flex;
	flex-direction: column;
	gap: 2vh;
}

.HoursChange {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	gap: 2vw;
}

.HoursChange > input {
	font-size: 1.3em;
	font-family: 'Montserrat';
}

.ClientCalls {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	align-items: center;
	width: 50vw;
	gap: 1vh 0;
}

.ClientCalls > div {
	display: flex;
	width: 60vw;
	justify-content: space-evenly;
}

.DashBoard > div {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60vh;
}

.StatisticPage > div {
	display: flex;
	flex-direction: column;
	div {
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		.CallStatistics {
			display: flex;
			flex-direction: column;
		}
	}
}

.ClientPage > div {
	margin: 4vh 0;
}

.ClientPage .Button {
	margin-top: 3vh;
}

.TextArea {
	width: 50vw;
	height: 30vh;
	min-width: 15vw;
	min-height: 10vh;
}

a.Active {
	background-color: var(--color1v1);
	color: var(--color1Text);
}

.ResponseByTime {
	display: flex;
	flex-direction: column;
	width: 100%;
	h1 {
		text-align: left;
	}
}

.recharts-legend-wrapper {
	display: flex;
	justify-content: center;
}

.CustomTooltip {
	background-color: white;
	padding: 1vh 1vw;
	border-radius: var(--borderRadius);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1vh;
	div {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	& > span {
		font-weight: bold;
	}
}
